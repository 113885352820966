/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'
import auth from '@/auth/authService'
import I18n from './i18n/i18n'

import firebase from 'firebase/app'
// import 'firebase/auth'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [

    {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: '/',
          name: 'app',
          component: () => import('./layouts/main/app.vue'),
          meta: {
            rule: 'editor',
            authRequired: true,
          }
        },
        {
          path: '/:id/bookingsList',
          name: 'bookings-list',
          component: () => import('./views/q-pages/Booking/BookingsList.vue'),
          meta: {
            rule: 'admin',
            authRequired: true,
            pageTitle: I18n.t('pages.bookingsList'),
          }
        },
        {
          path: '/:id/calendar/:date?',
          name: 'calendar',
          component: () => import('./views/q-pages/Booking/Calendar.vue'),
          meta: {
            rule: 'admin',
            no_scroll: true,
            authRequired: true,
            pageTitle: I18n.t('pages.calender'),
          }
        },
        {
          path: '/:id/bookings-leads',
          name: 'bookings-leads',
          component: () => import('./views/q-pages/Booking/BookingsLeads.vue'),
          meta: {
            rule: 'editor',
            no_scroll: true,
            authRequired: true,
            pageTitle: I18n.t('pages.bookingsLeads'),
          }
        },
        {
          path: '/:id/discount-coupons',
          name: 'discount-coupons',
          component: () => import('./views/q-pages/DiscountCoupons/DiscountCoupons.vue'),
          meta: {
            rule: 'editor',
            no_scroll: true,
            authRequired: true,
            pageTitle: I18n.t('pages.discountCoupons'),
          }
        },
        {
          path: '/:id/business-events',
          name: 'discount-coupons',
          component: () => import('./views/q-pages/BusinessEvents/BusinessEvents.vue'),
          meta: {
            rule: 'editor',
            no_scroll: true,
            authRequired: true,
            pageTitle: I18n.t('pages.businessEvents'),
          }
        },
        {
          path: '/:id/pricings-booking-options',
          name: 'pricings-booking-options',
          component: () => import('./views/q-pages/PricingsBookingOptions.vue'),
          meta: {
            rule: 'editor',
            no_scroll: true,
            authRequired: true,
            pageTitle: I18n.t('pages.PricingsBookingOptions'),
          }
        },
        {
          path: '/:id/booking-options',
          name: 'booking-options',
          component: () => import('./views/q-pages/BookingOptions/BookingOptions.vue'),
          meta: {
            rule: 'editor',
            no_scroll: true,
            authRequired: true,
            pageTitle: I18n.t('pages.BookingOptions'),
          }
        },
        {
          path: '/:id/services',
          name: 'services',
          component: () => import('./views/q-pages/Services/Services.vue'),
          meta: {
            rule: 'editor',
            no_scroll: true,
            authRequired: true,
            pageTitle: I18n.t('pages.Services'),
          }
        },
        {
          path: '/test',
          name: 'test',
          component: () => import('./views/q-pages/test.vue'),
          meta: {
            rule: 'admin',
            no_scroll: true,
            authRequired: false,
            pageTitle: 'test',
          }
        },

        // Bookings Management Page
        {
          path: '/:id/venue-statistics',
          name: 'venue-statistics',
          component: () => import('./views/q-pages/Statistics/BookingsCharts.vue'),
          meta: {
            rule: 'editor',
            no_scroll: true,
            authRequired: true,
            pageTitle: I18n.t('pages.BookingsCharts'),
          }
        },
        {
          path: '/:id/venue-settlements',
          name: 'venue-settlements',
          component: () => import('./views/q-pages/Settlements/VenueSettlements.vue'),
          meta: {
            rule: 'editor',
            no_scroll: true,
            authRequired: true,
            pageTitle: I18n.t('pages.venueSettlements'),
          }
        },
        {
          path: '/:id/reviews',
          name: 'reviews',
          component: () => import('./views/q-pages/Reviews/Reviews.vue'),
          meta: {
            rule: 'editor',
            no_scroll: true,
            authRequired: true,
            pageTitle: I18n.t('pages.reviews'),
          }
        },
        {
          path: '/:id/question-answers',
          name: 'question-answers',
          component: () => import('./views/q-pages/Q&As/QuestionsAndAnswers.vue'),
          meta: {
            rule: 'editor',
            no_scroll: true,
            authRequired: true,
            pageTitle: I18n.t('pages.questions'),
          }
        },
        {
          path: '/:id/business-marketing',
          name: 'business-marketing',
          component: () => import('./views/q-pages/BusinessMarketing/index.vue'),
          meta: {
            rule: 'editor',
            no_scroll: true,
            authRequired: true,
            pageTitle: I18n.t('pages.reviews'),
          }
        },
        {
          path: '/:id/bookings-management',
          name: 'bookings-management',
          component: () => import('./views/q-pages/BookingsManagement.vue'),
          meta: {
            rule: 'editor',
            no_scroll: true,
            authRequired: true,
            pageTitle: I18n.t('pages.bookingsManagement'),
          }
        },
        {
          path: '/:id/contract',
          name: 'contract',
          component: () => import('./views/q-pages/Contract.vue'),
          meta: {
            rule: 'editor',
            pageTitle: I18n.t('pages.contract')
          }
        },
        {
          path: '/:id/pricings-booking-options/:option_id/custom-pricings',
          name: 'custom-pricings',
          component: () => import('./views/q-pages/CustomPricing/CustomPricings.vue'),
          meta: {
            rule: 'editor',
            no_scroll: true,
            authRequired: true,
            pageTitle: I18n.t('pages.customPricings'),
          }
        },
        {
          path: '/:id/booking-options/:option_id/working-hours',
          name: 'option-working-hours',
          component: () => import('./views/q-pages/WorkingHours/OptionWorkingHours.vue'),
          meta: {
            rule: 'editor',
            no_scroll: true,
            authRequired: true,
            pageTitle: I18n.t('pages.optionWorkingHours')
          }
        },
        {
          path: '/:id/on-boarding',
          name: 'on-boarding',
          component: () => import('./views/q-pages/OnBoarding/OnBoarding.vue'),
          meta: {
            rule: 'editor',
            no_scroll: true,
            authRequired: true,
            pageTitle: I18n.t('pages.onBoarding'),
          }
        },
        {
          path: '/:id/on-boarding/thankyouPage',
          name: 'on-boarding',
          component: () => import('./views/q-pages/OnBoarding/ThankyouPage.vue'),
          meta: {
            rule: 'editor',
            no_scroll: true,
            authRequired: true,
            pageTitle: '',
          }
        },
        {
          path: '/:id/agent-profile',
          name: 'agent-profile',
          component: () => import('./views/q-pages/agentProfile.vue'),
          meta: {
            rule: 'editor',
            no_scroll: true,
            authRequired: true,
            pageTitle: '',
          }
        },
        {
          path:'/:id/our-services',
          name:'OurServices',
          component:()=>import('./views/q-pages/our-services.vue'),
          meta:{
            rule: 'editor',
            no_scroll: true,
            authRequired: true,
            pageTitle: '',


          }

        }
      ],
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // AUTH
        // =============================================================================
        {
          path: '/login',
          name: 'login',
          component: () => import('./views/q-pages/auth/Login.vue'),
          meta: {
            rule: 'admin'
          }
        },
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/callback',
          name: 'auth-callback',
          component: () => import('@/views/Callback.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/login',
          name: 'page-login',
          component: () => import('@/views/pages/login/Login.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/register',
          name: 'page-register',
          component: () => import('@/views/pages/register/Register.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/forgot-password',
          name: 'page-forgot-password',
          component: () => import('@/views/pages/ForgotPassword.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/reset-password',
          name: 'page-reset-password',
          component: () => import('@/views/pages/ResetPassword.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/lock-screen',
          name: 'page-lock-screen',
          component: () => import('@/views/pages/LockScreen.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/comingsoon',
          name: 'page-coming-soon',
          component: () => import('@/views/pages/ComingSoon.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error404.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/error-500',
          name: 'page-error-500',
          component: () => import('@/views/pages/Error500.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/not-authorized',
          name: 'page-not-authorized',
          component: () => import('@/views/pages/NotAuthorized.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/maintenance',
          name: 'page-maintenance',
          component: () => import('@/views/pages/Maintenance.vue'),
          meta: {
            rule: 'editor'
          }
        },
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/pages/error-404'
    }
  ]
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {
  firebase.auth().onAuthStateChanged(() => {

    // get firebase current user
    // const firebaseCurrentUser = firebase.auth().currentUser

    // if (
    //     to.path === "/pages/login" ||
    //     to.path === "/pages/forgot-password" ||
    //     to.path === "/pages/error-404" ||
    //     to.path === "/pages/error-500" ||
    //     to.path === "/pages/register" ||
    //     to.path === "/callback" ||
    //     to.path === "/pages/comingsoon" ||
    //     (auth.isAuthenticated() || firebaseCurrentUser)
    // ) {
    //     return next();
    // }

    // If auth required, check login. If login fails redirect to login page')
    if (to.meta.authRequired) {
      const token = localStorage.getItem('accessToken')
      if (!token){
        router.push({ path: '/login', query: { to: to.path }})
        return ;
      }
      const currentVenue = JSON.parse(localStorage.getItem('currentVenue'));
      const currentUser = JSON.parse(localStorage.getItem('currentUser')); // if the user is super admin then we will not show contract page
      if(currentVenue && currentVenue.agent_contract_signed_at == null && !currentUser.is_q_super_admin){
        if(router.currentRoute.path !==  `/${currentVenue.id}/contract`){
          router.push({ path: `/${currentVenue.id}/contract`})
        }
        return;
      }
    } else { // not logged in user
      let url = window.location.href;
      let strQueries = url.split('?')[1];
      localStorage.setItem('redirectToUrl', url)
    }


    return next()
    // Specify the current path as the customState parameter, meaning it
    // will be returned to the application after auth
    // auth.login({ target: to.path });

  })

})

export default router
