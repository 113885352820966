/*=========================================================================================
  File Name: moduleTodoMutations.js
  Description: Todo Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  SET_BOOKING_OPTIONS(state, bookingOptions) {
    state.bookingOptions = bookingOptions
  },
  UPDATE_BOOKING_OPTION(state, updatedBookingOption) {
    const index = state.bookingOptions.findIndex(bookingOption => bookingOption.id === updatedBookingOption.id)

    if(index !== -1) {
      state.bookingOptions.splice(index, 1, updatedBookingOption)
    }
  },
  DUPLICATE_BOOKING_OPTION(state, data) {
    if(data.isCurrentVenueSelected)
      state.bookingOptions.push(data.addedBookingOptions);
  },
  ADD_BOOKING_OPTION(state, addedBookingOptions) {
    state.bookingOptions.push(addedBookingOptions);
  },
  REMOVE_BOOKING_OPTION(state, deletedBookingOption) {
    const index = state.bookingOptions.findIndex(bookingOption => bookingOption.id === deletedBookingOption.id)

    if(index !== -1) {
      state.bookingOptions.splice(index, 1)
    }
  },
  SET_CURRENT_BOOKING_OPTION(state, bookingOption) {
    state.currentBookingOption = bookingOption
  },
  SET_PREVIEW_TIME_SLOTS(state, preview_time_slots) {
    state.preview_time_slots = preview_time_slots;
  },
  UPDATE_WORKING_HOUR_ENABLED(state, { index, value }) {
    state.currentBookingOption.working_hours[index].is_enabled = value;
  },
  UPDATE_START_TIME(state, { index, inIndex, value }) {
    state.currentBookingOption.working_hours[index].working_hours[inIndex].start_time = value;
  },
  UPDATE_END_TIME(state, { index, inIndex, value }) {
    state.currentBookingOption.working_hours[index].working_hours[inIndex].end_time = value;
  },
  UPDATE_DURATION(state, { value }) {
    state.currentBookingOption.duration = value;
  },
  UPDATE_DURATION_UNIT(state, { value }) {
    state.currentBookingOption.duration_unit = value;
  },
  SET_ERROR_AT_WORKING_TIME(state, { index, error }) {
    state.currentBookingOption.working_hours[index].error = error;
  },
  CLEAR_ERRORS_AT_WORKING_TIME(state, { index }) {
    state.currentBookingOption.working_hours[index].error = null;
  },
  ADD_WORKING_HOUR(state, { index, newWorkingHour }) {
    if (state.currentBookingOption.working_hours[index]) {
      state.currentBookingOption.working_hours[index].working_hours.push(newWorkingHour);
    } else {
      console.error(`No working hours found for index ${index}`);
    }
  },
  REMOVE_WORKING_HOUR(state, { outerIndex, innerIndex }) {
      if (
        state.currentBookingOption.working_hours[outerIndex] &&
        state.currentBookingOption.working_hours[outerIndex].working_hours[innerIndex]
      ) {
        state.currentBookingOption.working_hours[outerIndex].working_hours.splice(innerIndex, 1);
      } else {
        console.error(`Invalid indexes: outerIndex ${outerIndex}, innerIndex ${innerIndex}`);
      }
  },

}
