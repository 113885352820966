/*=========================================================================================
  File Name: moduleTodoActions.js
  Description: Todo Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from '@/axios.js'

export default {
  async getBookingOptions ({ commit },{venueId, query}) {
    try {

      const response = await axios.get(`/venues/${venueId}/bookingOptions`, { params: query })
      commit('SET_BOOKING_OPTIONS', response.data.data);

      return response;

    } catch (error) {

      return error.response;
    }
  },
  async updateBookingOption({ commit }, {venueId, data}) {
    let bookingOption = data.bookingOption;

    const formData = _basiBookingOptionFormData(bookingOption, data)

    try {
      const response = await axios.post(`/venues/${venueId}/bookingOptions/${bookingOption.id}`, formData)

      commit('UPDATE_BOOKING_OPTION', response.data.booking_option)

      return response;

    } catch (error) {
      return error.response;
    }

  },
  async duplicateBookingOption({ commit }, {venueId, data}) {
    let bookingOption = data.bookingOption;
    let copyCustomPricings = data.copyCustomPricings;
    let selectedBranches = data.selectedBranches;
    let agentVenues = data.agentVenues;

    const formData = _basiBookingOptionFormData(bookingOption, data);

    formData.append('is_duplicate', true);
    formData.append('duplicated_option_id', bookingOption.id);
    formData.append('copyCustomPricings', copyCustomPricings);
    if(agentVenues.length > 0 && selectedBranches && selectedBranches.length > 0) selectedBranches.forEach(branch => formData.append('selectedBranches[]', branch)); else formData.append('selectedBranches', []);
    if(agentVenues.length > 0) formData.append('branches', JSON.stringify(agentVenues));
    let isCurrentVenueSelected = selectedBranches.includes(Number(venueId))

    try {
      const response = await axios.post(`/venues/${venueId}/bookingOptions`, formData)
      let data = {
        addedBookingOptions: response.data.booking_option,
        isCurrentVenueSelected: isCurrentVenueSelected
      }

      commit('DUPLICATE_BOOKING_OPTION', data)

      return response;

    } catch (error) {

      return error.response;
    }

  },
  async addBookingOption({ commit }, {venueId, data}) {
    let bookingOption = data.bookingOption;

    const formData = _basiBookingOptionFormData(bookingOption, data);

    try {
      const response = await axios.post(`/venues/${venueId}/bookingOptions`, formData)

      commit('ADD_BOOKING_OPTION', response.data.booking_option)

      return response;

    } catch (error) {

      return error.response;
    }

  },
  async deleteBookingOption({ commit }, {venueId, data}) {
    let bookingOption = data.bookingOption;

    let params = {
      souce: data.source
    }

    try {
      const response = await axios.delete(`/venues/${venueId}/bookingOptions/${bookingOption.id}`, {data: params})

      if(response.status == 200)
        commit('REMOVE_BOOKING_OPTION', bookingOption);

      return response;

    } catch (error) {

      return error.response;
    }


  },
  async getBookingOption ({ commit }, {venueId, optionId}) {

    try {
      const response = await axios.get(`/venues/${venueId}/bookingOptions/${optionId}`)

      if (response.status === 200) {
        commit('SET_CURRENT_BOOKING_OPTION', response.data.bookingOption)
      }

      return response

    } catch (error) {
      return error.response
    }
  },
  async setBookingOptionTimeSlots ({ commit }, {venueId, optionId, data}) {

    try {
      const response = await axios.post(`/venues/${venueId}/bookingOptions/${optionId}/setOptionTimeSlotsValues`, data)

      if (response.status === 200) {
        commit('SET_CURRENT_BOOKING_OPTION', response.data.bookingOption)
      }

      return response

    } catch (error) {
      return error.response
    }
  },
  async toggleIsTimeSlotted ({ commit }, {venueId, optionId}) {

    try {
      const response = await axios.post(`/venues/${venueId}/bookingOptions/${optionId}/toggleIsTimeSlotted`)

      if (response.status === 200) {
        commit('SET_CURRENT_BOOKING_OPTION', response.data.bookingOption)
      }

      return response

    } catch (error) {
      return error.response
    }
  },
  async getPreviewTimeSlots({ commit }, {venueId, optionId, date}) {
    try {
      const response = await axios.get(`/venues/${venueId}/bookingOptions/${optionId}/getTimeSlotsForPreview?date=${date}`)

      if (response.status === 200) {
        commit('SET_PREVIEW_TIME_SLOTS', response.data.time_slots)
      }

      return response

    } catch (error) {
      return error.response
    }
  }
}




function _basiBookingOptionFormData(bookingOption, data) { // this function sets up the basic booking option form data object, used in [add, update, duplicate] actions
  let images = data.images;
  let selectedEventTypes = data.selectedEventTypes;
  let selectedRooms = data.selectedRooms;
  let source = data.source;

  const formData = new FormData();

  if(bookingOption.name) formData.append('name', bookingOption.name);
  if(bookingOption.description) formData.append('description', bookingOption.description);
  if(bookingOption.detailed_description) formData.append('detailed_description', bookingOption.detailed_description);
  if(bookingOption.pricing_type) formData.append('pricing_type', bookingOption.pricing_type);
  formData.append('tax_included', bookingOption.tax_included);
  formData.append('is_taxable', bookingOption.is_taxable);
  if(bookingOption.minimum_price) formData.append('minimum_price', bookingOption.minimum_price);
  if(selectedEventTypes && selectedEventTypes.length > 0) selectedEventTypes.forEach(eventType => formData.append('eventTypes[]', eventType)); else formData.append('eventTypes', []);
  if(selectedRooms && selectedRooms.length > 0) selectedRooms.forEach(room => formData.append('rooms[]', room)); else formData.append('rooms', []);
  // types
  // if(bookingOption.event_start_date) formData.append('event_start_date', bookingOption.event_start_date)
  // if(bookingOption.event_end_date) formData.append('event_end_date', bookingOption.event_end_date);
  formData.append('price', bookingOption.price);
  formData.append('man_price', bookingOption.man_price);
  formData.append('woman_price', bookingOption.woman_price);
  formData.append('has_seasonal_pricing', bookingOption.has_seasonal_pricing);
  formData.append('has_days_pricing', bookingOption.has_days_pricing);
  if(bookingOption.availability) formData.append('availability', bookingOption.availability);
  // if(bookingOption.event_start_date) formData.append('event_start_date', bookingOption.event_start_date);
  // if(bookingOption.event_end_date) formData.append('event_end_date', bookingOption.event_end_date);
  if(bookingOption.availability_type) formData.append('availability_type', bookingOption.availability_type);
  formData.append('bookings_size', bookingOption.bookings_size);

  if(bookingOption.men_count_max) formData.append('men_count_max', bookingOption.men_count_max); else if(bookingOption.men_count_max == 0) formData.append('men_count_max', 0);
  if(bookingOption.men_count_min) formData.append('men_count_min', bookingOption.men_count_min); else if(bookingOption.men_count_min == 0) formData.append('men_count_min', 0);
  if(bookingOption.women_count_max) formData.append('women_count_max', bookingOption.women_count_max); else if(bookingOption.women_count_max == 0) formData.append('women_count_max', 0);
  if(bookingOption.women_count_min) formData.append('women_count_min', bookingOption.women_count_min); else if(bookingOption.women_count_min == 0) formData.append('women_count_min', 0);

  if(images && images.length > 0) images.forEach(img => formData.append('images[]', img.imgUrl ? img.imgUrl : img.path));

  if(bookingOption.range_pricing && bookingOption.range_pricing.length > 0)
    bookingOption.range_pricing.forEach((rangePricing, index) => {
      formData.append(`range_pricing[${index}][men_count]`, rangePricing.men_count);
      formData.append(`range_pricing[${index}][man_price]`, rangePricing.man_price);
      formData.append(`range_pricing[${index}][women_count]`, rangePricing.women_count);
      formData.append(`range_pricing[${index}][woman_price]`, rangePricing.woman_price);
    });
  else
    formData.append('range_pricing', []);


  formData.append('source', source);


  return formData;
}
